<script>
	import { onMount } from "svelte";
	import calculator from "../stores/calculator.js";
	import { formatCurrency } from "../../configurator/common/format.js";

	export let cost;

	let localCost = cost;
	let downAmount = formatCurrency(localCost * $calculator.down);
	let downPercent = $calculator.down;
	let term = $calculator.term;
	let rate = ($calculator.rate * 100.0).toFixed(1);

	function toNumber(value, parser) {
		return parser(`${value}`.replace("$", "").replace(",", "").replace("%", "")) || 0.0;
	}

	function formatValues() {
		downPercent = Math.ceil($calculator.down * 100).toFixed();
		term = toNumber($calculator.term, parseInt).toFixed();
	}

	function didChangeValue() {
		formatValues();

		gtag("event", "calculator_value_changed");
	}

	function didChangeDownAmount(e) {
		calculator.updateValues({
			down: toNumber(downAmount, parseFloat) / localCost
		});
	}

	function didBlurDownAmount() {
		downAmount = formatCurrency(localCost * $calculator.down);
	}

	function didChangeDownPercent() {
		calculator.updateValues({
			down: toNumber(downPercent, parseFloat) / 100.0
		});
		downAmount = formatCurrency(localCost * $calculator.down);
	}

	function didChangeTerm() {
		calculator.updateValues({ term: toNumber(term, parseInt) });
	}

	function didChangeRate() {
		calculator.updateValues({ rate: toNumber(rate, parseFloat) / 100.0 });
	}

	$: isFieldInvalid = (name) => {
		return $calculator.invalidValues.includes(name);
	};

	onMount(formatValues);
</script>

<form>
	<div class="form-row">
		<div class="field merge" class:invalid={isFieldInvalid("down")}>
			<label for="calculator-down">Down payment</label>
			<div class="input wide">
				<input
					id="calculator-down"
					name="down-amount"
					type="text"
					inputmode="decimal"
					bind:value={downAmount}
					on:input={didChangeDownAmount}
					on:input={didChangeValue}
					on:blur={didBlurDownAmount} />
			</div>
			<div class="input narrow">
				<div class="overlay"><span>{downPercent}</span>%</div>
				<input
					name="down-percent"
					type="text"
					inputmode="decimal"
					bind:value={downPercent}
					on:input={didChangeDownPercent}
					on:input={didChangeValue} />
			</div>
		</div>
	</div>

	<div class="form-row">
		<div class="field wide" class:invalid={isFieldInvalid("term")}>
			<label for="calculator-term">Term</label>
			<div class="input">
				<div class="overlay">
					<span>{term}</span>
					{term === "1" ? "year" : "years"}
				</div>
				<input
					id="calculator-term"
					name="term"
					type="text"
					inputmode="decimal"
					bind:value={term}
					on:input={didChangeTerm}
					on:input={didChangeValue} />
			</div>
		</div>

		<div class="field narrow" class:invalid={isFieldInvalid("rate")}>
			<label for="calculator-rate">Interest rate</label>
			<div class="input">
				<div class="overlay"><span>{rate}</span>%</div>
				<input
					id="calculator-rate"
					name="rate"
					type="text"
					inputmode="decimal"
					bind:value={rate}
					on:input={didChangeRate}
					on:input={didChangeValue} />
			</div>
		</div>
	</div>
</form>

<style>
	.invalid,
	.invalid input,
	.invalid label {
		opacity: 1;
		color: rgb(187, 38, 44) !important;
	}

	.invalid .overlay {
		display: none;
	}

	.form-row {
		display: flex;
		margin-top: 16px;
	}

	.field {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}

	label {
		opacity: 0.4;
		margin-bottom: 8px;
		width: 100%;
		font-size: 14px;
		line-height: 18px;
	}

	.input {
		position: relative;
		box-sizing: border-box;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.03);
		width: 100%;
	}

	@media (max-width: 768px) {
		.input {
			border-radius: 8px;
			border-radius: 10px;
		}
	}

	.merge .input:first-of-type {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.merge .input:last-of-type {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.input.wide {
		width: 70%;
	}

	@media (max-width: 768px) {
		.input.wide {
			width: 50%;
		}
	}

	.field.wide {
		margin-right: 16px;
		width: calc(70% - 16px);
	}

	@media (max-width: 768px) {
		.field.wide {
			margin-right: 8px;
			width: calc(50% - 8px);
		}
	}

	.input.narrow,
	.field.narrow {
		width: 30%;
	}

	@media (max-width: 768px) {
		.input.narrow {
			width: 50%;
		}

		.field.narrow {
			margin-left: 8px;
			width: calc(50% - 8px);
		}
	}

	.input:focus-within {
		box-shadow: 0 0 0 4px rgba(0, 150, 247, 0.25);
	}

	.invalid .input:focus-within {
		box-shadow: 0 0 0 4px rgba(187, 38, 44, 0.25);
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0.5px;
		opacity: 0.5;
		padding: 16px 20px 18px 20px;
		pointer-events: none;
		font-size: 15px;
	}

	@media (max-width: 768px) {
		.overlay {
			padding: 10px 12px;
			font-size: 16px;
		}
	}

	.overlay span {
		opacity: 0;
	}

	input {
		appearance: none;
		box-sizing: border-box;
		outline: none;
		border: none;
		background: transparent;
		padding: 16px 20px 18px 20px;
		width: 100%;
		font-size: 15px;
	}

	@media (max-width: 768px) {
		input {
			padding: 10px 12px 11px 12px;
			font-size: 16px;
		}
	}
</style>
