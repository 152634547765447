import { derived } from "svelte/store";
import config from "../../../../../.backyard/config.json";
import { completeConfiguration, variantAtKeyPath } from "../common/options.js";
import configuration from "./configuration.js";

function safeCostEval(code, configuration) {
    const mask = {};

    for (p in this) mask[p] = undefined;

    return new Function("c", `let v; with(this) { v = (${code})(c) }; return v;`).call(
        mask,
        configuration
    );
}

export function costForVariantWithKey(variantKey, optionKey, configuration) {
    const variant = variantAtKeyPath(optionKey, variantKey);

    if (!variant || !variant.cost) {
        return 0;
    }

    if (Number.isFinite(variant.cost)) {
        return variant.cost;
    }

    const cost = safeCostEval(variant.cost, completeConfiguration(configuration));

    if (!cost) {
        return 0;
    }

    return cost;
}

export function costForConfiguration(currentConfiguration) {
    const configuration = completeConfiguration(currentConfiguration);

    const base = Object.entries(configuration).reduce(
        (result, [optionKey, variantKey]) =>
            result + costForVariantWithKey(variantKey, optionKey, configuration),
        0
    );

    const installation = config.installation_cost[configuration.region][configuration.layout];
    const total = base + installation;

    return { total, installation, base };
}

const store = derived([configuration], ([$configuration]) => costForConfiguration($configuration));

export default store;
