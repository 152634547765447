<script>
	import { afterUpdate } from "svelte";
	import { formatCurrency } from "../../configurator/common/format.js";
	import calculator, { mortgageForCost } from "../stores/calculator.js";
	import { costForConfiguration } from "../../configurator/stores/cost.js";

	export let model;
	export let cost;

	let previousMortgage;

	$: mortgage =
		$calculator.invalidValues.length === 0
			? mortgageForCost(cost || costForConfiguration({ layout: model }).total, $calculator)
			: previousMortgage;

	afterUpdate(() => {
		previousMortgage = mortgage;
	});

	function didClickReserveButton(e) {
		e.preventDefault();
		e.stopPropagation();

		Calculator.hide();

		if (document.body.classList.contains("hello")) {
			window.getQuickEstimate(e);
			gtag("event", "calculator_reserve");
		} else {
			openRoute("/backyard/configure");
			gtag("event", "calculator_configure");
		}
	}
</script>

<div class="result">
	<div class="mortgage">
		<div class="monthly type-42 m-type-36">
			{formatCurrency(mortgage.monthly)}<span class="color-text-60"> /mo </span>
		</div>
		<div class="total type-14 color-text-60">
			{formatCurrency(mortgage.total)} total mortgage
		</div>
		<div class="extra type-14 color-text-40">Tax and insurance not included</div>
	</div>
	<a
		href="/backyard/configure#bonewhite+{model}"
		class="button-rounded button-rounded-blue button-rounded-12 type-18 m-type-15"
		on:click={didClickReserveButton}>
		{document.body.classList.contains("hello") ? "Get a quick estimate" : "Design yours"}
	</a>
</div>

<style>
	:global(.configurator .result .button-rounded) {
		display: none;
	}

	.result {
		display: flex;
		flex-direction: column;
		gap: 42px;
		margin-top: 36px;
		text-align: center;
	}

	@media (max-width: 768px) {
		.result {
			gap: 20px;
			margin-top: 24px;
		}
	}

	.mortgage {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		text-align: center;
	}

	@media (max-width: 768px) {
		.mortgage {
			margin-bottom: 24px;
			text-align: center;
		}
	}

	.monthly {
		margin-bottom: 5px;
	}

	.button-rounded {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		height: 60px;
		white-space: nowrap;
	}
</style>
