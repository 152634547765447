import { writable } from "svelte/store";
import persisted from "../../common/persisted.js";
import synchronized from "../../common/synchronized.js";

export function mortgageForCost(cost, calculator) {
    const borrowedMoney = cost * (1.0 - calculator.down);
    const lengthOfLoan = calculator.term * 12;
    const interestRate = calculator.rate * 100;
    const calculedInterest = interestRate / 100;
    const interestReady = calculedInterest / 12;
    const percentage = interestReady;
    const percentagePlusOne = interestReady + 1;
    const exponentiationOperator = percentagePlusOne ** lengthOfLoan;
    const firstDividend = percentage * exponentiationOperator;
    const secondDividend = exponentiationOperator - 1;
    const division = firstDividend / secondDividend;
    const mortgage = borrowedMoney;
    const monthly = mortgage * division;

    return { total: mortgage, monthly };
}

const INITIAL_STATE = { down: 0.1, term: 30, rate: 0.07, invalidValues: [] };

function createStore() {
    const { set, update, subscribe } = writable(INITIAL_STATE);

    return {
        subscribe,
        set,
        update,

        reset() {
            set({ ...INITIAL_STATE });
        }
    };
}

const store = persisted(
    "calculator:v5",
    synchronized("calculator", createStore())
);

store.updateValues = function updateValues(changes) {
    store.update((state) => {
        const next = { ...state, ...changes };
        const invalidValues = [];
        const isDownValid = next.down >= 0.0 && next.down < 1.0;
        const isTermValid = next.term > 0 && next.term <= 30;
        const isRateValid =
            Number.isFinite(parseFloat(next.rate)) &&
            parseFloat(next.rate) > 0.0 &&
            parseFloat(next.rate) < 1.0;

        if (isDownValid === false) {
            invalidValues.push("down");
        }

        if (isTermValid === false) {
            invalidValues.push("term");
        }

        if (isRateValid === false) {
            invalidValues.push("rate");
        }

        if (invalidValues.length > 0) {
            return { ...next, invalidValues };
        }

        const nextState = {
            ...next,
            invalidValues
        };

        return nextState;
    });
};

export default store;
