<script>
	function didClickTalkButton() {
		window.dispatchEvent(new CustomEvent("modal:dismiss"));
		setTimeout(Contact.show, 300);
	}
</script>

<div class="header">
	<h1 class="type-36 m-type-24">Estimate payments.</h1>
	<p class="type-15 m-type-14 color-text-60">
		There are many ways to finance Backyard, and we'll help you find the best choice for you.
		Available rates, terms, and product offerings will be based on your unique financial
		situation.
	</p>
	<p class="type-15 m-type-14 color-text-60">
		Need financing? <a on:click={didClickTalkButton}>Talk to a specialist</a>
	</p>
</div>

<style>
	.header {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	p {
		margin: 0;
		padding: 0;
	}
</style>
